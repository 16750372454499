<template>
  <page-view class="goodMater-all" v-infinite-scroll="loadScroll" :infinite-scroll-disabled="disabled"
    :infinite-scroll-immediate="false" :up-class="'goodMater-all'">
    <div class="searcr">
      <el-input placeholder="请输入材料名称" v-model.trim="input" @change="getMaterList" clearable>
        <el-button slot="append" icon="el-icon-search" @click="getMaterList"></el-button>
      </el-input>
    </div>
    <div class="top_spe_item">
      <div class="spe-item">
        <div class="content">
          <ul>
            <li :class="{ active: materIndex.typeIndex === null }" @click="changeType('all')">
              全部
            </li>

            <li v-for="item in goodFenLei" :key="item.id" :class="{ active: materIndex.typeIndex === item.id }"
              @click="changeType(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <!-- <div
          class="spe-item-btn"
          ref="openTypeRef"
          @click="onOpenType('openTypeRef')"
          v-show="showOpenType"
        >
          <el-button size="mini" plain
            >{{ openType ? "收起" : "展开"
            }}<i
              :class="[openType ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            ></i
          ></el-button>
        </div> -->
      </div>
    </div>
    <div class="spe-box">
      <!-- <div class="spe-item hiden">
        <div class="name">服务类型:</div>
        <div class="content">
          <ul>
            <li
              :class="{ active: materIndex.typeIndex === null }"
              @click="changeType('all')"
            >
              全部
            </li>
            <li
              v-for="item in goodFenLei"
              :key="item.id"
              :class="{ active: materIndex.typeIndex === item.id }"
              @click="changeType(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div
          class="spe-item-btn"
          ref="openTypeRef"
          @click="onOpenType('openTypeRef')"
          v-show="showOpenType"
        >
          <el-button size="mini" plain
            >{{ openType ? "收起" : "展开"
            }}<i
              :class="[openType ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"
            ></i
          ></el-button>
        </div>
      </div> -->
      <div class="spe-item hiden">
        <div class="name">产品分类:</div>
        <div class="content">
          <ul>
            <li :class="{ active: proClassIndex === null }" @click="changePro('all')">
              全部
            </li>
            <li v-for="item in proClass" :key="item.id" :class="{ active: proClassIndex === item.id }"
              @click="changePro(item)">
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="spe-item-btn" ref="openProRef" @click="onOpenPro('openProRef')" v-show="showOpen">
          <el-button size="mini" plain>{{ openPro ? "收起" : "展开"
          }}<i :class="[openPro ? 'el-icon-arrow-up' : 'el-icon-arrow-down']"></i></el-button>
        </div>
      </div>
      <div class="spe-item">
        <div class="name">产品规格:</div>
        <div class="content">
          <ul>
            <li class="active">全部</li>
          </ul>
        </div>
      </div>
      <div class="spe-item">
        <div class="name">时间:</div>
        <div class="content">
          <ul>
            <li class="active">全部</li>
          </ul>
        </div>
      </div>
      <div class="spe-item">
        <div class="name">色系:</div>
        <div class="content">
          <ul>
            <li class="active">全部</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="sort">
      <div class="sort-item">综合排序</div>
      <div class="sort-item">销量</div>
      <div class="sort-item">星级</div>
      <div class="sort-item">价格<i class="el-icon-arrow-down"></i></div>
      <div class="sort-item">
        <el-input v-model="price1" size="mini"></el-input>
        <div class="line"></div>
        <el-input v-model="price2" size="mini"></el-input>
        <el-button size="mini" class="confirm" @click="qujian">确认</el-button>
      </div>
    </div>
    <div class="goods-water">
      <div class="goods-content">
        <waterfall :img-width="isMobile ? 175 : 260" :water-data="waterDataCopy" @click-com="toComIndex"
          @on-click-water="toDetails"></waterfall>
        <el-empty description="暂无数据" v-if="waterDataCopy.length < 1"></el-empty>
      </div>
      <center v-if="loading">
        <div class="bouncing-bar">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </center>
      <center v-if="noMore && waterDataCopy.length > 0">没有更多了</center>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import Waterfall from "@/components/waterfall/waterfall.vue";
import { mapState } from "vuex";
export default {
  name: "ShareGoodsAll",
  components: { Waterfall },
  data() {
    return {
      input: "", //搜索框
      price1: null, //价格start
      price2: null, //价格end
      typeIndex: null, //类型选中
      proClass: [], //分类list
      proClassIndex: null, //分类选中
      total: 0, //总条数
      current: 0, //当前页,
      selectId: null, //当前请求id
      waterData: [],
      waterDataCopy: [],
      loading: false,
      openPro: false,
      showOpen: true,
      openType: false,
      showOpenType: true,
    };
  },
  computed: {
    ...mapState(["goodFenLei", "search", "screenWidth", "materIndex"]), //使用全局的分类
    noMore() {
      return this.waterData.length >= this.total;
    },
    disabled() {
      return this.loading || this.noMore;
    },
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
  },
  // watch: {
  //   search: {
  //     handler(newValue) {
  //       this.input = newValue.value;
  //       //修改网页标题
  //       let title = "百川商展-找材料";
  //       document.title = this.input ? `${title}-${this.input}` : `${title}`;
  //       this.getMaterList();
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    if (this.$route.query.input && this.$route.query.input != '') {
      this.input = this.$route.query.input
    }
    this.proClass = this.goodFenLei.map((item) => item.children).flat();
    this.lockType();
    this.getMaterList();
  },
  // mounted() {
  //   this.$nextTick(() => {
  //     //判断服务类型高度是否显示展开
  //     this.showOpenType =
  //       this.$refs["openTypeRef"].previousElementSibling.firstElementChild
  //         .offsetHeight > 30;
  //   });
  // },
  activated() {
    this.proClass = this.goodFenLei.map((item) => item.children).flat();
    this.lockType();
    this.getMaterList();
  },
  methods: {
    qujian() {
      if (!this.price1 && !this.price2 || this.price1 == '' || this.price2 == '') {//为空，原数据填充
        this.waterDataCopy = JSON.parse(JSON.stringify(this.waterData))
        console.log(this.waterDataCopy);
      } else {
        let res = this.waterDataCopy.filter((item) => item.price >= this.price1 && item.price <= this.price2)
        this.waterDataCopy = res
      }
    },
    //通过点击分类进来锁定分类
    lockType() {
      if (Number(this.$route.query.id)) {
        if (Number(this.$route.query.pid) == 0) {
          // 说明是一级
          this.selectId = Number(this.$route.query.id);
          this.materIndex.typeIndex = Number(this.$route.query.id);
          this.proClass = this.goodFenLei.find((item) => item.id == Number(this.$route.query.id))?.children;
        } else {
          // 二级
          this.selectId = Number(this.$route.query.id);
          this.materIndex.typeIndex = Number(this.$route.query.id);
          let list = this.goodFenLei.find((item) => item.id == Number(this.$route.query.id))?.children;
          this.proClass = list;
          this.proClassIndex = Number(this.$route.query.id);
        }
      } else {
        setTimeout(() => {
          this.changeType('all')
        }, 500);
      }
    },
    changeType(item) {
      if (item === "all") {
        //点击全部
        this.materIndex.typeIndex = null;
        this.proClass = this.goodFenLei.map((item) => item.children).flat();
      } else {
        this.materIndex.typeIndex = item.id;
        this.proClass = item.children;
      }
      this.$nextTick(() => {
        //切换类型判断分类高度是否显示展开
        this.showOpen =
          this.$refs["openProRef"].previousElementSibling.firstElementChild.offsetHeight > 30;
      });
      this.openPro = false;
      this.$refs["openProRef"].previousElementSibling.style.height = 24 + "px";
      this.proClassIndex = null;
      this.selectId = this.materIndex.typeIndex;
      this.getMaterList();
    },
    changePro(item) {
      if (item === "all") {
        this.proClassIndex = null;
        this.selectId = this.materIndex.typeIndex;
      } else {
        this.proClassIndex = item.id;
        this.selectId = item.id;
      }
      this.getMaterList();
    },
    onOpenPro(refDom) {
      let content = this.$refs[refDom].previousElementSibling;
      if (this.openPro) {
        this.openPro = false;
        content.style.height = 24 + "px";
      } else {
        this.openPro = true;
        content.style.height = content.firstElementChild.offsetHeight + "px";
      }
    },
    onOpenType(refDom) {
      let content = this.$refs[refDom].previousElementSibling;
      if (this.openType) {
        this.openType = false;
        content.style.height = 24 + "px";
      } else {
        this.openType = true;
        content.style.height = content.firstElementChild.offsetHeight + "px";
      }
    },

    //获取材料列表
    async getMaterList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.current = 0;
      let result;
      if (this.$route.query.wasOne && this.$route.query.wasOne == 1) {
        result = await this.$api.getMaterList1({
          categoryId: this.selectId,
          index: this.current,
          size: 10,
          name: this.input,
          type: 17,
          price: 1,
        });
      } else {
        result = await this.$api.getMaterList({
          categoryId: this.selectId,
          index: this.current,
          size: 10,
          name: this.input,
          type: 17,
        });
      }
      loading.close();
      this.total = result.data?.size;
      this.waterData = result.data?.list;
      this.waterDataCopy = result.data?.list;
    },
    async loadScroll() {
      if (this.disabled) {
        return false; //没有更多了
      }
      this.loading = true;
      this.current++;
      let result;
      if (this.$route.query.wasOne && this.$route.query.wasOne == 1) {
        result = await this.$api.getMaterList1({
          categoryId: this.selectId,
          index: this.current,
          size: 10,
          name: this.input,
          type: 17,
          price: 1,
        });
      } else {
        result = await this.$api.getMaterList({
          categoryId: this.selectId,
          index: this.current,
          size: 10,
          name: this.input,
          type: 17,
        });
      }
      this.loading = false;
      this.waterData.push(...result.data.list);
    },
    toComIndex(v) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: { syscompanyid: v.companyid },
      });
      window.open(openRoute.href, "_blank");
    },
    toDetails(v) {
      this.$router.push({
        name: "ArticlesDetailGood",
        params: {
          goodsId: v.id,
          isGoodFrom:'false',
        },
      })
    },
  },
};
</script>
<style lang='scss' scoped>
.goodMater-all {
  flex: 1;
  background-color: #f5f5f5;

  .searcr {
    @include flexbox(row, center, center);
    margin: 20px 0;

    :deep(.el-input) {
      width: 40%;

      @media screen and (max-width: 1200px) {
        width: 90%;
      }

      .el-input__inner {
        box-shadow: 0px 20px 20px 0px rgba(70, 156, 76, 0.1);
        border-radius: 45px 0 0 45px;
        border: 1px solid #469c4c;
        border-right: none;
      }

      .el-input-group__append {
        background-color: #fff;
        color: #469c4c;
        font-size: 18px;
        border: 1px solid #469c4c;
        border-left: none;
        border-radius: 0 45px 45px 0;
      }
    }
  }

  .top_spe_item {
    width: 100%;
    background-color: #FFFFFF;
    margin: auto;

    .spe-item {
      @include flexbox(row, space-between, flex-start);

      .content {
        flex: 1;
        overflow: hidden;
        padding: 1%;

        >ul {
          width: 60%;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;

          @media screen and (max-width: 600px) {
            width: 100%;
          }

          li {
            box-sizing: border-box;
            color: #282828;
            font-size: 14px;
            padding: 0.5% 1%;
            cursor: pointer;
            position: relative;

            &::after {
              content: '';
              height: 65%;
              width: 1px;
              position: absolute;
              top: 50%;
              background-color: #dedede;
              right: 0%;
              transform: translateY(-60%);
            }

            &.active {
              color: $primaryColor;
            }
          }
        }
      }

      .spe-item-btn {
        .el-button {
          padding-left: 10px;
          padding-right: 10px;

          i {
            margin-left: 5px;
          }
        }
      }
    }

    .hiden {
      .content {
        height: 24px;
        overflow: hidden;
        transition: all 0.2s linear;
      }
    }
  }

  .spe-box {
    width: $containerWidth;
    background-color: #fff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    margin: auto;
    padding: 20px;
    box-sizing: border-box;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }

    .spe-item {
      @include flexbox(row, space-between, flex-start);
      border-bottom: 1px dashed;
      padding: 10px 0;

      .name {
        font-size: 14px;
        margin: 5px 0;
      }

      .content {
        flex: 1;
        overflow: hidden;
        padding: 0 10px;

        >ul {
          @include flexbox(row) {
            flex-wrap: wrap;
          }

          li {
            color: #979797;
            font-size: 14px;
            cursor: pointer;
            margin: 5px 0;
            margin-right: 18px;

            &.active {
              color: $primaryColor;
            }
          }
        }
      }

      .spe-item-btn {
        .el-button {
          padding-left: 10px;
          padding-right: 10px;

          i {
            margin-left: 5px;
          }
        }
      }
    }

    .hiden {
      .content {
        height: 24px;
        overflow: hidden;
        transition: all 0.2s linear;
      }
    }
  }

  .sort {
    width: $containerWidth;
    background-color: #fff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.15);
    margin: 10px auto;
    padding: 6px 20px;
    box-sizing: border-box;
    @include flexbox(row, flex-start, center);
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
      width: 100%;
      padding-bottom: 0.3rem;
    }

    .sort-item {
      @include flexbox(row, flex-start, center);
      margin-right: 20px;
      color: #787878;
      font-size: 12px;

      @media screen and (max-width: 1200px) {
        margin-top: 0.2rem;
      }

      >.line {
        width: 20px;
        height: 1px;
        background-color: #787878;
        margin: 0 2px;
      }

      .el-input {
        width: 60px;
      }

      .confirm {
        margin-left: 10px;
      }
    }
  }

  .goods-water {
    padding: 50px 0;
    padding-top: 30px;
    background-color: #f8f9fb;

    .goods-content {
      width: $containerWidth;
      margin: auto;

      @media screen and (max-width: 1200px) {
        width: 100%;
      }
    }
  }

  .bouncing-bar {
    display: flex;
    justify-content: center;

    .line {
      height: 20px;
      width: 4px;
      background: #439c4c;
      margin-right: 4px;
      border-radius: 6px;
      animation: line-bounce 1s infinite ease-in-out;

      &:nth-child(1) {
        animation-delay: 0s;
      }

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.3s;
      }

      &:nth-child(4) {
        animation-delay: 0.4s;
      }
    }
  }

  @keyframes line-bounce {
    0% {
      transform: scale(1);
    }

    20% {
      transform: scale(1, 2);
    }

    40% {
      transform: scale(1);
    }
  }
}
</style>